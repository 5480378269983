/* Font Merriweather */

@font-face {
  font-family: 'Merriweather';
  src: url('fonts/Merriweather-Light.woff2') format('woff2'),
  url('fonts/Merriweather-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Merriweather';
  src: url('fonts/Merriweather-LightItalic.woff2') format('woff2'),
  url('fonts/Merriweather-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Merriweather';
  src: url('fonts/Merriweather-Regular.woff2') format('woff2'),
  url('fonts/Merriweather-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Merriweather';
  src: url('fonts/Merriweather-Italic.woff2') format('woff2'),
  url('fonts/Merriweather-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Merriweather';
  src: url('fonts/Merriweather-Bold.woff2') format('woff2'),
  url('fonts/Merriweather-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Merriweather';
  src: url('fonts/Merriweather-BoldItalic.woff2') format('woff2'),
  url('fonts/Merriweather-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

/* Font Roboto */

@font-face {
  font-family: 'Roboto';
  src: url('fonts/Roboto-Light.woff2') format('woff2'),
  url('fonts/Roboto-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('fonts/Roboto-LightItalic.woff2') format('woff2'),
  url('fonts/Roboto-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('fonts/Roboto-Regular.woff2') format('woff2'),
  url('fonts/Roboto-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('fonts/Roboto-Italic.woff2') format('woff2'),
  url('fonts/Roboto-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('fonts/Roboto-Medium.woff2') format('woff2'),
  url('fonts/Roboto-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('fonts/Roboto-MediumItalic.woff2') format('woff2'),
  url('fonts/Roboto-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('fonts/Roboto-Bold.woff2') format('woff2'),
  url('fonts/Roboto-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('fonts/Roboto-BoldItalic.woff2') format('woff2'),
  url('fonts/Roboto-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

